<template>
  <div class="verification-container">
    <header class="header">
      <div class="title">登录</div>
      <div class="info">请绑定您的手机号并登录</div>
    </header>
    <!-- 表单 -->
    <van-form :show-error-message="false" ref="formData">
      <van-field
        label="+86"
        label-width="40px"
        name="手机号"
        :center="true"
        v-model="formData.mobile"
        placeholder="输入手机号"
        :rules="[{ required: true, message: '请输入手机号' }]"
      />
      <van-field
        name="验证码"
        :center="true"
        v-model="formData.verification"
        placeholder="请输入验证码"
        :rules="[{ required: true, message: '请输入验证码' }]"
      >
        <template #button>
          <van-button
            @click="smsSendMobile"
            native-type="button"
            size="small"
            >{{ captchaTime === 60 ? "发送验证码" : captchaTime + "s" }}</van-button
          >
        </template>
      </van-field>
      <div class="submitBtn">
        <van-button round block type="info" native-type="submit"
          >确定</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
let captchaTimeOut = ''; // 验证码计时器
export default {
  data() {
    return {
      formData: {
        mobile: '15848162214',
        verification: ''
      },
      captchaTime: 60,
      captchaTimeOut
    }
  },
  methods: {
    // 获取手机验证码
    async smsSendMobile() {
      const { mobile } = this.formData
      // 获取验证码时确认有手机号
      await this.$refs.formData.validateField('手机号')
      this.captchaTime--;
      captchaTimeOut = setInterval(() => {
        this.captchaTime--;
        if (this.captchaTime === 0) {
          console.log(123)
          clearInterval(captchaTimeOut);
          this.captchaTime = 60;
        }
      }, 1000);
    },
  }

}
</script>

<style lang="less" scoped>
.verification-container {
  padding: 0 20px;
  .header {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 27px;
    .title {
      font-size: 24px;
      color: #161616;
    }
    .info {
      margin-top: 7px;
      font-size: 12px;
      color: #999;
    }
  }
  .submitBtn {
    margin-top: 44px;
  }

  .van-cell {
    position: relative;
    border-radius: 24px;
    height: 46px;
    border: 1px solid #e5e5e5;
    margin-bottom: 14px;
    padding-left: 36px;
    padding-top: 8px;
    padding-bottom: 8px;
    &::before {
      content: "";
      position: absolute;
      left: 10px;
      width: 23px;
      height: 23px;
    }

    &:nth-child(1) {
      &::before {
        background: url("../../assets/images/login_icon_iphone@3x.png");
        background-size: 23px 23px;
      }
    }
    &:nth-child(2) {
      &::before {
        background: url("../../assets/images/login_icon_password@3x.png");
        background-size: 23px 23px;
      }
    }
    /deep/ .van-cell__title {
      border-right: 1px solid #d8d8d8;
    }
    /deep/ .van-button {
      font-size: 12px;
      color: #999;
      height: 26px;
      line-height: 28px;
      width: 80px;
      background-color: #f2f2f2;
      border-radius: 13px;
      text-align: center;
      padding-right: 8px;
    }
  }
}
</style>